<template>
  <div class="container">
    <el-dialog
      :title="'请选择' + dialogTitle"
      width="800px"
      v-model="dialogVisible"
    >
      <div class="wrap-container">
        <div class="wrap">
          
          <div v-if="isSeachID">
            <el-input
              placeholder="请输入群ID"
              prefix-icon="el-icon-search"
              v-model="searchObj.groupId"
              style="width:100%"
              @change="changeGroupId"
              :disabled="!canEdit"
              clearable
            ></el-input>
          </div>
          <div class="left-header" v-else>
            <el-input
              :placeholder="'请输入'+placeholderTitle"
              prefix-icon="el-icon-search"
              v-model="searchObj.key"
              style="width: 200px"
              @change="changeName"
              :disabled="!canEdit"
            ></el-input>
            <div
              class="header-group"
              @click="groupFieldAction"
              v-if="type == 0 || type == 3"
            >
              <div>
                <!-- <i class="el-icon-info"></i> -->
                <el-icon :size="14" :color="'#999'">
                  <Filter />
                </el-icon>

                <div>
                  {{ searchObj.tagStr == "" ? "全部标签" : searchObj.tagStr }}
                </div>
              </div>
              <i class="el-icon-arrow-down"></i>
            </div>
          </div>
          <div class="body-wrap">
            <div class="cell" v-for="(item, index) in groupList"
              :key="index"
              @click="selectAction(index)">
              <div class="cell-l" >
                <img src="@/assets/imgs/group_default.jpg" v-if="(type == 0 || type == 3)&&!item.ChatRoomHeadImg"/>
                <img src="@/assets/imgs/default_user.png" alt="" v-else-if="(type == 1|| type == 2)&&!item.ChatRoomHeadImg">
                <img :src="item.ChatRoomHeadImg" v-else/>
                <div class="title" v-if="type == 1 || type == 2">
                  {{ item.NickName }}<span v-if="type == 1">({{item.WxAlias}})</span>
                </div>
                <div class="title" v-else> 
                  <el-popover v-if="type==0 || type==3" placement="top-start" title="" trigger="hover" :content="item.GroupName+'('+item.MemberCount+'人)'" >
                        <template #reference>
                          <div>
                            <span>{{ item.GroupName }}</span>
                            <span> ({{ item.MemberCount }}人)</span>
                          </div>
                        </template>
                  </el-popover>
                  <div v-else>
                    {{ item.GroupName }}
                    <span v-if="type != 4 && type!=5&& type!=6">({{ item.MemberCount }}人)</span>
                    <span v-if="type==6&&item.WxAlias">({{item.WxAlias}})</span>
                  </div>
                </div>
              </div>
              <div class="cell-r" >
                <el-icon :size="25" :color="'#BFB18A'" v-if="item.isSelect">
                  <CircleCheckFilled />
                </el-icon>
              </div>
            </div>
          </div>
          <!-- <div class="line"></div> -->
        </div>

        <div class="wrap">
          <div class="left-header">
            <div class="selected">
              已选择(<span>{{ selectList.length || 0 }}</span
              >)
            </div>
            <el-button type="primary" @click="clearAction">清空</el-button>
            <!-- <div class="clear-all" >清空</div> -->
          </div>
          <div class="body-wrap">
            <div class="cell" v-for="(item, index) in selectList" :key="index">
                <div class="cell-l">
                  <img src="@/assets/imgs/group_default.jpg" v-if="(type == 0 || type == 3)&&!item.ChatRoomHeadImg"/>
                  <img src="@/assets/imgs/default_user.png" alt="" v-else-if="(type == 1|| type == 2)&&!item.ChatRoomHeadImg">
                  <img :src="item.ChatRoomHeadImg" v-else/>
                  <div class="title" v-if="type == 1 || type == 2">
                    {{ item.NickName }}<span v-if="type == 1">({{item.WxAlias}})</span>
                  </div>
                  <div class="title" v-else>
                    <el-popover v-if="type==0 || type==3" placement="top-start" title="" trigger="hover" :content="item.GroupName+'('+item.MemberCount+'人)'" >
                          <template #reference>
                            <div>
                              <span>{{ item.GroupName }}</span>
                              <span> ({{ item.MemberCount }}人)</span>
                            </div>
                          </template>
                    </el-popover>
                    <div v-else>
                      {{ item.GroupName }}
                      <span v-if="type != 4 && type!=5&& type!=6">({{ item.MemberCount }}人)</span>
                      <span v-if="type==6&&item.WxAlias">({{item.WxAlias}})</span>
                    </div>
                  </div>
                </div>
                
                <div class="cell-r" @click="deleteAction(index)">
                  <el-icon :size="25" :color="'#BFB18A'">
                    <CircleCloseFilled />
                  </el-icon>
                </div>
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <div class="wrap-foot">
            <el-checkbox
              v-model="allselect"
              @change="changeAll"
              v-if="type != 3 && type!=4 && type!=5"
              :disabled="!canEdit"
              >全选</el-checkbox
            >
            <div class="foot-text">共<span>{{ groupList.length || 0 }}</span>个 
              <p v-if="type!=5">{{ dialogTitle }}</p>
              <p v-if="type==5">{{tipTitle}}</p>
            </div>
          </div>
          <div>
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="confirmAction">确 定</el-button>
          </div>
        </div>
      </template>
    </el-dialog>
    <GroupFieldCop ref="groupFieldRef" @onConfirm="sureAction" />
    
  </div>
</template>

<script>
import { reactive, ref, onMounted, toRef,watch } from "vue";
import GroupFieldCop from "./GroupFieldCop";
import {
  CircleCheckFilled,
  CircleCloseFilled,
  Filter,
} from "@element-plus/icons";
import { GetAllGroup, OpenGroups } from "@/helper/group";
import { GetRobotSimpleList,RobotGetCompanyContact } from "@/helper/robot";
import { GetGroupMemberSimpleList,GroupGetCompanyContact,GetListBySerialNo} from "@/helper/group";
import {GetMiniProgramSourceGroup} from '@/helper/material'
export default {
  components: {
    GroupFieldCop,
    CircleCloseFilled,
    CircleCheckFilled,
    Filter,
  },
  props:{
      isSeachID:{
        type:Boolean,
        default:false
      }
  },
  setup(props, context) {
    const dialogVisible = ref(false);
    const type = ref(0);
    const robotInfo=reactive({
      info:{},
      no:""
    })
    const sid=ref("")
    const canEdit = ref(true);
    const dialogTitle = ref("群聊");
    const tipTitle=ref("企业员工")
    const placeholderTitle=ref("群名")
    const searchObj = reactive({
      key: "",
      taglist: [],
      tagStr: "",
      taglion: 0,
      tagIds:[],
      groupId:[]
    });

    //pageType:0 群聊  1:机器人 2.排除用户 3.群单选 4.群成员以及管理员 5.群内企业好友 6,企业员工
    function initCop(pageType = 0, arr = [],edit=true,info={},SID,serialNo="") {
      dialogVisible.value = true;
      type.value = pageType;
      canEdit.value  = edit==true?true:false
      robotInfo.info=info
      robotInfo.no=serialNo
      sid.value=SID
 
      if (pageType == 1) {
        dialogTitle.value = "助手";
        placeholderTitle.value="助手名称或ID"
      } else if (pageType == 2) {
        dialogTitle.value = "客户";
        placeholderTitle.value="姓名"
      }else if(pageType==4){
        dialogTitle.value='群成员以及管理员'
        placeholderTitle.value="姓名"
      }else if(pageType==5){
        dialogTitle.value='管理员'
        placeholderTitle.value="姓名或ID"
      }else if(pageType==6){
        dialogTitle.value='企业员工进群'
        placeholderTitle.value="姓名或ID"
      }
      selectList.value = arr? JSON.parse(JSON.stringify(arr)): [];
      resetData();
      if(!props.isSeachID){
        searchAction();
      }
      
    }
    //重置
    function resetData() {
      searchObj.key = "";
      groupList.value = [];
      allselect.value = false;
      searchObj.taglist = [];
      searchObj.tagStr = [];
      searchObj.taglion = 0;
      searchObj.tagIds = [];
      
    }
    const groupFieldRef = ref(null);
    function groupFieldAction() {
      if(!canEdit.value){
        return
      }
      groupFieldRef.value.initCop(0, searchObj.taglist, searchObj.taglion);
    }
    /*************搜索****************/
    const groupList = ref([]);
    const selectList = ref([]);
    const allselect = ref(false);
    function changeName() {
      searchAction(true);
    }
    function changeGroupId(){
      let arr = selectList.value.map((ele) => ele.GroupID);
      if(searchObj.groupId&&searchObj.groupId.length>0){
        GetListBySerialNo({GroupSerialNo:searchObj.groupId}).then(res=>{
        groupList.value = res.List;
            res.List.forEach((item) => {
              if (arr.indexOf(item.GroupID) > -1) {
                item.isSelect = true;
              }
        });
        checkAllSelect()
        })
      } else {
        groupList.value = []
      }
      
    }
    //搜索群
    function searchAction(noCompare) {
      for(let item of selectList.value){
         if(type.value == 1){
          item.ChatRoomHeadImg =item.HeadImgUrl
        }else if(type.value == 2){
           item.ChatRoomHeadImg =item.MemberHeadImgUrl
        }else if(type.value==5){
          item.GroupID=item.MemberSerialNo
          item.GroupName = item.NickName;
          item.ChatRoomHeadImg=item.HeadImgUrl
        }
      }
      if (type.value == 1) {
        let arr = selectList.value.map((ele) => ele.RobotID);
        GetRobotSimpleList({ Name: searchObj.key }).then((res) => {
          res.List.forEach((item) => {
            if (arr.indexOf(item.RobotID) > -1) {
              item.isSelect = true;
            }
            item.GroupID =  item.RobotID;
            item.GroupName = item.NickName;
            item.ChatRoomHeadImg=item.HeadImgUrl
          });
          groupList.value = res.List;
          checkAllSelect()
        });
      } else if (type.value == 2) {
        let arr = selectList.value.map((ele) => ele.MemberID);
         
        if (searchObj.key.length > 0) {
          GetGroupMemberSimpleList({ Name: searchObj.key }).then((res) => {
            groupList.value = res.List;
            res.List.forEach((item) => {
              if (arr.indexOf(item.MemberID) > -1) {
                item.isSelect = true;
              }
              item.GroupID =  item.MemberID;
              item.GroupName = item.NickName;
              item.ChatRoomHeadImg=item.MemberHeadImgUrl
            });
            checkAllSelect()
          });
        }
      }else if(type.value == 4){
        let arr= selectList.value.map((ele) => ele.MemberSerialNo);
        let param={
          RobotSerialNo:robotInfo.info.robot.SerialNo,
          Name:searchObj.key
        }
        RobotGetCompanyContact(param).then(res=>{
          groupList.value=res.List || []
          groupList.value.forEach(item=>{
            if (arr.indexOf(item.MemberSerialNo) > -1) {
              item.isSelect = true;
            }
            item.GroupID=item.MemberSerialNo
            item.GroupName = item.NickName;
            item.ChatRoomHeadImg=item.HeadImgUrl
          })
        })
      }else if(type.value==5){
        let arr=selectList.value.map((ele) => ele.MemberSerialNo);
        let param={
          RobotSerialNo:robotInfo.info.Robot?robotInfo.info.Robot.SerialNo:robotInfo.no,
          GroupID:sid.value,
          Name:searchObj.key
        }
        GroupGetCompanyContact(param).then(res=>{
          groupList.value=res.List
          res.List.forEach(item=>{
            if (arr.indexOf(item.MemberSerialNo) > -1) {
              item.isSelect = true;
            }
            item.GroupID=item.MemberSerialNo
            item.GroupName = item.NickName;
            item.ChatRoomHeadImg=item.HeadImgUrl
          })
        })
      }else if(type.value == 6){
        let param={
          RobotSerialNo:robotInfo.info.Robot?robotInfo.info.Robot.SerialNo:robotInfo.no,
          Name:searchObj.key,
          GroupID:sid.value
        }
        RobotGetCompanyContact(param).then(res=>{
          groupList.value=res.List || []
          res.List.forEach(item=>{
            item.GroupID=item.MemberSerialNo
            item.GroupName = item.NickName;
            item.ChatRoomHeadImg=item.HeadImgUrl
          })
        })
      }else {
        let arr = []
        for(let item of selectList.value){
          if(type.value==3){
            item.ChatRoomHeadImg=item.GroupHeadImg
          }
          if(Number.isInteger(item)){
            arr.push(item)
          } else {
            arr.push(item.GroupID)
          }
          
        }
        let param = {
          TagIDs: searchObj.tagIds || [],
          XJoin: searchObj.taglion || 0,
          Name: searchObj.key || "",
          RuleSerialNo:robotInfo.info.RuleSerialNo || ""
        };
        let selectItemArr = []
        GetAllGroup(param).then((res) => {
          groupList.value = res.List;
          res.List.forEach((item) => {
            if (arr.indexOf(item.GroupID) > -1) {
              item.isSelect = true;
              selectItemArr.push(item)
            }
          });
          selectList.value = noCompare?selectList.value:selectItemArr
          
          checkAllSelect()
        });
        
      }
    }

    /*************点击事件****************/
    //选择、
    function selectAction(index) {
      if(!canEdit.value){
        return
      }
      let item = groupList.value[index];
      if (item.isSelect) {
        let tag = -1;
        selectList.value.forEach((v, key) => {
          if (v.GroupID == item.GroupID) {
            tag = key;
            return false;
          }
        });
        if (tag > -1) {
          selectList.value.splice(tag, 1);
        }
        item.isSelect = false;
        checkAllSelect()
      } else {
        item.isSelect = true;
        if((type.value==4||type.value==5)&&selectList.value.length>2){
          item.isSelect=false
          return
        }
        if (type.value == 3 && selectList.value.length > 0) {
          groupList.value.forEach((item,index2)=>{
            if(index2!=index){
              item.isSelect=false
            }
          })
          selectList.value[0].isSelect = false;
          selectList.value = [item];
          return;
        }
        selectList.value.push(item);
        checkAllSelect()
        
      }
    }

    function checkAllSelect(){
      let flag = false
        for(let item of  groupList.value){
          if(!item.isSelect){
              flag = true
              break
          }
        }
        if(flag){
           allselect.value = false;  
        } else {
          allselect.value = true;  
        }
    }

    //删除
    function deleteAction(index) {
      if(!canEdit.value){
        return
      }
      let item = selectList.value[index];
      groupList.value.forEach((ele) => {
        if (ele.GroupID == item.GroupID) {
          ele.isSelect = false;
          return false;
        }
      });
      allselect.value = false;
      selectList.value.splice(index, 1);
    }

    //清空
    function clearAction() {
      if(!canEdit.value){
        return
      }

      for (let item of groupList.value){
        item.isSelect  = false
      }
      selectList.value =[]
      allselect.value = false;
    }

    //取消全选
    function cancelAllSelect(){
      let arr = []
      for (let item of groupList.value){
        item.isSelect  = false
        arr.push(item.GroupID)
      }

      let selectArr = []
      for(let item of selectList.value){
        if(arr.indexOf(item.GroupID)<=-1){
          selectArr.push(item)
        }
      }
      selectList.value = selectArr;
      allselect.value = false;
    }

    //全选
    function changeAll(ent) {
      if(!canEdit.value){
        return
      }
      if(groupList.value&&groupList.value.length<=0){
        allselect.value = false;
        return
      }
      if (!allselect.value) {
        cancelAllSelect();
      } else {
        let arr = selectList.value.map(item=>item.GroupID)
        groupList.value.forEach((item) => {
          if(arr.indexOf(item.GroupID)<=-1){
            selectList.value.push(item);
          }
          item.isSelect = true;
        });
      }
    }

    //标签条件
    function sureAction(type, arr) {
      if (type == 0) {
        searchObj.taglist = arr;
        let tags = [];
        let tagIDs = [];
        arr.forEach((item) => {
          tags.push(item.TagName);
          tagIDs.push(item.ID);
        });
        searchObj.tagStr = tags.join(",");
        searchObj.taglion = 0;
        searchObj.tagIds = tagIDs;
      } else if (type == 1) {
        searchObj.taglist = arr;
        let tags = [];
        let tagIDs = [];
        arr.forEach((item) => {
          tags.push(item.TagName);
          tagIDs.push(item.ID);
        });
        searchObj.tagStr = tags.join(",");
        searchObj.taglion = 1;
        searchObj.tagIds = tagIDs;
      } else if (type == -1) {
        searchObj.taglist = [];
        searchObj.tagStr = "无任何标签";
        searchObj.taglion = -1;
        searchObj.tagIds = [];
      }
      searchAction(true)
    }

    //确认
    function confirmAction() {
      if(!canEdit.value){
        return
      }
      dialogVisible.value = false;
      context.emit("onConfirm", selectList.value,robotInfo.info);
    }

    return {
      dialogVisible,
      initCop,
      confirmAction,
      searchObj,
      groupList,
      selectList,
      selectAction,
      deleteAction,
      clearAction,
      allselect,
      changeAll,
      groupFieldAction,
      groupFieldRef,
      sureAction,
      type,
      dialogTitle,
      changeName,
      searchAction,
      resetData,
      checkAllSelect,
      canEdit,
      cancelAllSelect,
      robotInfo,
      placeholderTitle,
      sid,
      tipTitle,
      changeGroupId
    };
  },
};
</script>
<style lang='scss' scoped>
.wrap-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.wrap {
  width: 50%;
  max-height: 500px;
  position: relative;
  // border: 1px solid #ccc;
  // border-radius: 10x;
  padding: 10px 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 0 5px;
  // box-shadow: -1px 1px 10px #888888;

  display: flex;
  flex-direction: column;
  .left-header {
    width: 100%;
    height: 40px;
    // margin: 0 5px 0px;
    padding: 10px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #000;
    font-size: 14px;
    flex-shrink: 0;
    // box-sizing: border-box;
    border-bottom: 1px dashed #ccc;

    .header-group {
      width: 140px;
      height: 28px;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 5px;
      box-sizing: border-box;
      font-size: 12px;
      color: #999999;

      display: flex;
      justify-content: space-between;

      i {
        margin-right: 5px;
      }

      div {
        width: 100px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    span {
      color: $color-common;
    }
    .clear-all {
      color: $color-common;
    }
  }

  .body-wrap {
    width: 90%;
    margin: 0 auto;
    overflow: auto;
    font-size: 14px;
    color: #000;

    .cell {
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #f2f2f2;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .cell-l {
        width: 80%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .title {
          margin-left: 10px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }
        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .cell-r {
        width: 10%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .line {
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: #f2f2f2;
  }
}
.dialog-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .wrap-foot {
    width: 350px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #000;
    font-size: 14px;
    // margin-top: 10px;
    padding: 0 20px;

    .foot-text {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }
    span {
      color: $color-common;
    }
  }
}
.cell-wrap{
  width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

</style>