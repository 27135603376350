import $axios from '../assets/js/request.js'
import crypto from '../assets/js/crypto.js'
let baseURL = process.env.VUE_APP_URL


//获取当前素材
export function GetMaterialList(data) {
    return $axios.post(`${baseURL}/Material/GetList`,data);

}

//新增链接素材
export function CreateLinkMaterial(data) {
    return $axios.post(`${baseURL}/Material/Create`,data);
    
}

//新增图片或视频
export function CreateImageOrVideo(data) {
    return $axios.post(`${baseURL}/Material/CreateImageOrVideo?type=${data.Type}`,data.formData);
   
}

//修改素材备注、视频素材封面
export function UpdateImageOrVideo(data) {
    return $axios.post(`${baseURL}/Material/Update`,data);
   
}

//图片及视频上传
export function UpLoadImageOrVideo(type,data) {
    return $axios.post(`${baseURL}/Material/Upload?type=${type}`,data);
  
}

//删除素材
export function disable(data) {
    return $axios.post(`${baseURL}/Material/Disable`,data);
 
}

//获取小程序来源
export function GetMiniProgramSourceGroup(data) {
    return $axios.post(`${baseURL}/config/GetMiniProgramSourceGroup`,data);
   
}

//设置小程序来源
export function SetMiniProgramSourceGroup(data) {
    return $axios.post(`${baseURL}/config/SetMiniProgramSourceGroup`,data);
   
}